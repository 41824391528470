.survey-button {
  font-size: 2em;
}

@media (max-width: 750px) {
  .survey-button {
    font-size: 1em;
  }
}

.btn.active.focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn:active:focus,
.btn:focus {
  outline: none;
}
